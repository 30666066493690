import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Our Team | Asta Systems';

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/1.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0',
    paddingTop:'12.5rem'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Our Team</h2>
            
        </div>
    </section>
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           {/* <h2 className="home-h2 team-h2">Our Team</h2> */}
              
      
          

  <table className='team-table'>
     
    <tr>
      <th>No.</th>
      <th>Name</th>
      <th>
Title
</th>
      
    </tr>

     <tr>
      <td>1.</td>
      <td>Mr. Jack Mwebaze </td>
      <td>Board Member  </td>
      {/* <td>
       <ul>
            <li>Civil works for the periodic maintenance of Kampala Northern Bypass Phase 1 (21 KM).</li>
          <li> Construction of selected road infrastructure in Kamuli Municipal Council, Lugazi Municipal Council and Jinja City under batch 2- USMID-AF PROJECT – MLHUD.</li> 
          <li>Upgrading of gravel/earth/surfaced roads to asphalt concrete ancillalies area in Kamuli Municipal Council including road ancillaries (industrial area road and Byaino road).</li> 
         <li>Upgrading of gravel/ earth/surfaced road to asphalt concrete paved roads in Lugazi Municipality (Kulubya road - 0.36km, Kinyoro road - 0.86km, Nabugabo close - 0.30km, Nabugabo road - 0.36km, market street 01 - 0.4km, market street 03 - 0.18km & market street 04 - 0.06km).</li> 
         <li> Embankment works on Nsango–Bulega swamp crossing between Bugiri and Butaleja districts (4.5km) located in Kisoko-Hasheba village Nsangaso parish, Buluguya sub county in Bugiri district. - under Bugiri District Local Government. </li>
         <li> Construction of lecture and laboratory block for Busitema University maritime institute, Namasagali campus, Kamuli district.</li>
      <li>The design and build of two ferry landing platforms and axillary facilities at Buvuma and Kiyindi --- under Ministry of Agriculture, Animal Industry and Fisheries (MAAIF), with support from the International Fund for Agricultural Development (IFAD).</li>
      <li>Construction of the drainage infrastructure in Masaka Municipality.</li>
      <li>The proposed fuel station in Dwaliro Village, Kasanda District.</li>
<li>The proposed fuel station in Nalututu Village, Kasanda District.</li>
 <li>Uganda Support to Municipal Infrastructure Development (USMID) program; funded by World Bank (WB) - Jinja main street, Busoga and Engineer Zikusooka roads in Jinja Municipality.</li>     
   <li>Para-transit/informal transport system & street usage in Greater Kampala Metropolitan Area (GKMA) in view of the planned Bus Rapid Transit (BRT) pilot project. _under Ministry of Works and Transport and KCCA obtained funding from African Development Bank.</li>   
    <li> Manufacturing factories established in Liao Shen industrial park Kepeeka (plot m06 channel lane Bugolobi, P. O. Box 22994, Kampala – Uganda).</li>
<li>Mining Projects for Kaolin and Clay for Hua Hui International Group Company Ltd Kapeeka.</li>
<li>Stone Quarry project located in Kapeka Sub county, Nakaseke District for Liao Shen industrial Park Plot M06 Channel Lane Bugolobi, P. O. Box 22994, Kampala – Uganda.</li>
 <li>Consultancy services for the feasibility study and detailed engineering design for Kampala Outer Beltway road project (KOB) under Uganda National Roads Authority (UNRA).</li> 
 <li>Consultancy services for the feasibility study and detailed engineering design for the VVIP express route (Nakasero – northern bypass expressway) under Uganda National Roads Authority (UNRA).</li>  
  <li>Upgrading of Kapchorwa _ Suam road funded by African Development Bank under Uganda National Roads Authority (UNRA).</li>   
  <li> The construction of a helicopter hangar and other associated facilities at the flying school (East African Civil Aviation Academy) in Soroti Municipality under Uganda People’s Defense Force (UPDF) received funds from the United States Embassy. </li> 
  <li>  Design and build for civil works for the construction of strategic bridges in northern-region under Uganda National Roads Authority (UNRA). </li> 
      </ul>     
      </td> */}
    </tr>

    <tr>
      <td>2.</td>
      <td>Mr. Francis Kasozi 
</td>
      <td>
Board Member
</td>
    </tr>


    <tr>
      <td>3.</td>
      <td>Mr Peter Nionzima 
</td>
      <td>General Manager </td>
      {/* <td>
            <ul>
                  <li>
                        Embankment works on Nsango–Bulega
swamp crossing between Bugiri and Butaleja
Districts (4.5km) located in Kisoko-Hasheba
village Nsangaso Parish, Buluguya Subcouty
in Bugiri District under Bugiri District
local government
                  </li>
<li>
      Construction of lecture and laboratory block
for Busitema University maritime institute,
Namasagali Campus, Kamuli District.
</li>
            </ul>
      </td> */}
    </tr>


  </table>



 


         </div>
    </section>
     
    </>
  )
}

export default Team