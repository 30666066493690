import React,{useEffect} from 'react'
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

useEffect(()=>{
document.title = 'Our Services | Asta Systems';

},[]);


  return (
    <>

     <section className='home-service-cover home-service-cover-a'>
    
     <h2 className='home-h2 home-service-h2'>Our Services</h2>
    {/* <div className='line1'> 
    <div className='line2'></div>
    </div> */}
    
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          
          {/* <PowerCarousel></PowerCarousel> */}
          <img src='./images/1.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Project Planning & Management</h3>
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
         
          {/* <PowerCarousel></PowerCarousel> */}
          <img src='./images/11.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Procurement </h3>
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/6.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Engineering</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/2.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Construction Management</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
         <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/12.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Architectural Design</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
        
       <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/13.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Welding & Fabrication</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/14.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Transport & Logistics</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>

        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/8.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Installation</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/15.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Maintenance</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
         <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/16.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Operations</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
         <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/17.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Commissioning & Decommissioning</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
         <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/18.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Environmental & Social Impact Assessment</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <img src='./images/19.jpg' className='home-service-image'></img>
          <h3 className='home-service-title'>Geological, Geotechnical, Geophysical & Topographical Surveys</h3>
          {/* <PowerCarousel></PowerCarousel> */}
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
    </div>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link>
<Link to='features' className='home-btn' onClick={scrollFunc}>Key Features</Link> */}

        </div>

  </section>
   
    {/* <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>Docksid 3 Construction Limited</strong> is a prominent and locally owned construction company in Uganda. We are duly registered and specializing in Building Construction Services, Civil Engineering Structures, Road Works, Landscaping, Electrical Works, Water Works, Construction Management and Installation of Security Systems.
</p>
<p className='home-p'>
Our happy clients include; Government Ministries & Departments, Non-Governmental Organizations, Places of Worship, Schools and Universities, Financial Institutions and Private Individauls. We are here to meet your construction needs.</p>


<p className='home-p'>
We are committed to providing the best possible construction services to ensure cost effective and successful projects. We work closely with our clients to accurately interpret their dreams/visions in drawings and bring them to the desired reality through construction solutions to meet their needs. More so, we communicate with our clients throughout the construction process to keep them informed of progress and to ensure that our projects keeps on schedule and within budget.</p>

 
        </div>

    </section> */}
  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.663), rgba(0, 0, 0, 0.663)), url("./images/34.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We pledge to maintain the highest level of quality assurance and ensure unequalled customer satisfaction.

</p>
        </div>
    </section> */}
    
 {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'><strong> REJO ENGINEERING LIMITED</strong> began as a general works contractor in 2014. Over the
years, the company has undertaken many challenging projects and accumulated skills, know-how and
experiences in delivering building projects, water projects, road and bridge construction
projects and build solutions, project management services, building trades and related
engineering works.
<br></br> <br></br>
Today, <strong>REJO ENGINEERING LIMITED</strong> takes on the role of main contractor for small,
medium and large size construction projects and performs project management services to
coordinate specialist trades for industrial/commercial projects. We also provide design inputs
and engineering solutions as value-add services to our clients.
<br></br> <br></br>
We take pride in our delivery, thus our clients can always be assured that only the most experienced and qualified people are serving them, all the time.</p>
                     </div>


                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>

       </div>

  </section> */}

 
 {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.863)), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Objectives</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'> To provide the most efficient installation, repair
and maintenance solutions for clients’
dependable work continuity.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To ensure timely, correct and best quality
delivery of services and supplies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To grow and maintain trusted relationships
with our clients, partners and government.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To foster client up-to-date market awareness.

</p>
                     </div>
                    
                </div>
                   

           </article>

           




       </div>

  </section> */}

  
   {/* <section className='section-cover section-cover2 section-cover3' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("./images/9.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3 goals-text4 goals-text5'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Services</h3>
              <div className='vm-line'></div>
              <div className='services-container-a'>
              <div className='goals-list goals-list1 goals-list2'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Consultancy.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Feasibility Studies.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Conceptual Design.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Front End Engineering Design.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Detailed Engineering.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Project Management.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Construction Management.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Supply.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Logistical Support.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Procurement.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Commissioning.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Operations.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Maintenance.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Decommissioning.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Benchmarking.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Pre-Export Verification for Conformity.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Leak Detection.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Hydrotesting.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Non-Destructive Testing (NDT).
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Factory Acceptance Tests.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Consumption.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Metering.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Time Sheets.
                            </p>
                     </div>
                </div>
                 <div className='goals-list goals-list1 goals-list2'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Welding & Fabrication.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Painting.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Installation.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Inspection.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Audit.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Monitoring.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Quality Control & Quality Assurance.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Health & Safety.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Environment & Social Impact Assessment.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Material Selection.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Fatigue Analysis.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Corrosion Control.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Information Technology, .
                            </p>
                     </div>
                       <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Transaction.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Invoicing.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Receipt.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Tax.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Engagement.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Contracting.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Advisory.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Distribution.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Alignment Sheets.
                            </p>
                     </div>
                </div>
                 <div className='goals-list goals-list1 goals-list2'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Intelligent Pigging.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Surveillance.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Routing.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Geophysical, Geological, & Geotechnical Investigation.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Formation.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Constitution.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Policy.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Legislation.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Regulation.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Negotiation.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Licensing.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Exploration.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Subsurface.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Development.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Production.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Reservoir.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Completion.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Optimisation.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Well Drilling.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Flow.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Feeder.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Transmission.
                            </p>
                     </div>
                    
                </div>
            </div>   

           </article>

       </div>
                    
  </section> */}

  {/* <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
           <div className='line1'> 
           <div className='line2'></div>
           </div>
          <p className='clients-para'></p>
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/1.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/2.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/3.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/4.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/5.png' alt='client' className='client-image'></img>
           </article>

          </div> 
    </section> */}
   
    
  
    </>
  )
}

export default Home