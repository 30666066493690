import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Road Construction & Maintenance | RAK ENGINEERING LTD';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
              

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Road Construction & Maintenance</h3>
                       <p>Road construction requires the creation of an engineered continuous roadbed, overcoming geographic obstacles and having grades low enough to permit vehicle or foot travel whilst meeting standards set by official guidelines. At RAK ENGINEERING LTD, we practice the art of road construction of highest quality. Below are our road construction services:</p>
                       <ul>
                    <li>  Highways and service roads</li>
<li>Car parks and hard standings.</li>
<li>Street lighting</li>
<li>Sidewalks, Paving and Curbing</li>
<li>Drainage</li>
                     
                     </ul>

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

           
           </div>

    </section>
  )
}

export default Services