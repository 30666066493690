import React,{useEffect} from 'react'
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCog} from 'react-icons/fa';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

useEffect(()=>{
document.title = 'Sectors | Asta Systems';

},[]);


  return (
    <>
    {/* <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>Docksid 3 Construction Limited</strong> is a prominent and locally owned construction company in Uganda. We are duly registered and specializing in Building Construction Services, Civil Engineering Structures, Road Works, Landscaping, Electrical Works, Water Works, Construction Management and Installation of Security Systems.
</p>
<p className='home-p'>
Our happy clients include; Government Ministries & Departments, Non-Governmental Organizations, Places of Worship, Schools and Universities, Financial Institutions and Private Individauls. We are here to meet your construction needs.</p>


<p className='home-p'>
We are committed to providing the best possible construction services to ensure cost effective and successful projects. We work closely with our clients to accurately interpret their dreams/visions in drawings and bring them to the desired reality through construction solutions to meet their needs. More so, we communicate with our clients throughout the construction process to keep them informed of progress and to ensure that our projects keeps on schedule and within budget.</p>

 
        </div>

    </section> */}
  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.663), rgba(0, 0, 0, 0.663)), url("./images/34.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We pledge to maintain the highest level of quality assurance and ensure unequalled customer satisfaction.

</p>
        </div>
    </section> */}
    
 {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'><strong> REJO ENGINEERING LIMITED</strong> began as a general works contractor in 2014. Over the
years, the company has undertaken many challenging projects and accumulated skills, know-how and
experiences in delivering building projects, water projects, road and bridge construction
projects and build solutions, project management services, building trades and related
engineering works.
<br></br> <br></br>
Today, <strong>REJO ENGINEERING LIMITED</strong> takes on the role of main contractor for small,
medium and large size construction projects and performs project management services to
coordinate specialist trades for industrial/commercial projects. We also provide design inputs
and engineering solutions as value-add services to our clients.
<br></br> <br></br>
We take pride in our delivery, thus our clients can always be assured that only the most experienced and qualified people are serving them, all the time.</p>
                     </div>


                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>

       </div>

  </section> */}

 
 {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.863)), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Objectives</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'> To provide the most efficient installation, repair
and maintenance solutions for clients’
dependable work continuity.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To ensure timely, correct and best quality
delivery of services and supplies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To grow and maintain trusted relationships
with our clients, partners and government.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To foster client up-to-date market awareness.

</p>
                     </div>
                    
                </div>
                   

           </article>

           




       </div>

  </section> */}

  
   <section className='section-cover section-cover2 section-cover3' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("./images/3.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3 goals-text4 goals-text5'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2> */}
              <h3 className='title-h3'>Sectors</h3>
              <div className='vm-line'></div>
              <div className='services-container-a'>
              <div className='goals-list goals-list1 goals-list2'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Energy.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Construction.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Manufacturing.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Real Estate.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'> Agriculture.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Utilities.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Brewing.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Society.
                            </p>
                     </div>
                     
                </div>
                 <div className='goals-list goals-list1 goals-list2'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Telecommunication.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Transport (Road, Rail, Pipeline, Air, Water, Space).
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Mining.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Processing.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Refining.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Storage.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Compliance.
                            </p>
                     </div>
                      
                      
                </div>
                 <div className='goals-list goals-list1 goals-list2'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>State.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Government.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Ministry.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Department.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Agency.
                            </p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Authority.
                            </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCog></FaCog>
                          </span>
                          <p className='goal-description goal-description-a'>Commercial.
                            </p>
                     </div>
                     
                     
                       
                </div>
            </div>   

           </article>

       </div>
                    
  </section>

  {/* <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
           <div className='line1'> 
           <div className='line2'></div>
           </div>
          <p className='clients-para'></p>
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/1.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/2.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/3.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/4.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/5.png' alt='client' className='client-image'></img>
           </article>

          </div> 
    </section> */}
   
    
  
    </>
  )
}

export default Home