import React, {useEffect} from 'react';
import WareHouse from '../components/WareHouse';
import CommercialBlock from '../components/CommercialBlock';
import Healthcenter from '../components/Healthcenter';
import TH from '../components/TH';
import ClassroomBlocks from '../components/ClassRoomBlocks';
import WaterSupply from '../components/WaterSupply';
import Hardware from '../components/Hardware';
import Residential1 from '../components/Residential1';
import Residential2 from '../components/Residential2';
import Residential3 from '../components/Residential3';
import Residential4 from '../components/Residential4';
import Residential5 from '../components/Residential5';
import Roads1 from '../components/Roads1';
import Land1 from '../components/Land1';
import Land2 from '../components/Land2';
import Farming from '../components/Farming';
import Bridge1 from '../components/Bridge1';
import Aluminium1 from '../components/Aluminium1';
import Commercial1 from '../components/Commercial1';
import Roads2 from '../components/Roads2';
import Excavation from '../components/Excavation';
import Warehouse1 from '../components/Warehouse1';
import Aswa1 from '../components/Aswa1';

const Gallery = () => {
  useEffect(()=>{
document.title = 'Gallery | Gimlovu Environmental Consult Ltd';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Gallery</h2>
      <div className='content-container gallery gallery1 gallery2'>
        {/* <article className='gallery-content'>
          <img src='./images/37.jpg' className='gallery-image' alt='image'></img>
           <Residential5></Residential5>
           <p>Construction of a residential house at Kasenge, Wakiso District </p>
         </article>
          */}


         <article className='gallery-content'>
          <img src='./images/22.jpg' className='gallery-image' alt='image'></img>
           <p>Indication meeting with the workers</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/23.jpg' className='gallery-image' alt='image'></img>
           <p>Training of workers on Lugazi roads project</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/24.jpg' className='gallery-image' alt='image'></img>
           <p>Kamuli Byaino road construction under USMID
projects
</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/25.jpg' className='gallery-image' alt='image'></img>
           <p>Kamuli Byaino road construction under USMID
projects
</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/26.jpg' className='gallery-image' alt='image'></img>
           <p>Site clearing on the Bulucheke-Muchomu-Nyende
road project- Bududa district

</p>
         </article>
          <article className='gallery-content'>
          <img src='./images/27.jpg' className='gallery-image' alt='image'></img>
           <p>Fire trainings on upgrading of Kulubya,
Kinyoro,Nabugabo close, Nabugabo road, Market street 1, Market street 3, Market street 04 roads
project under USMID-AF


</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/28.jpg' className='gallery-image' alt='image'></img>
           <p>One of the Stakeholder meetings</p>
         </article>
          <article className='gallery-content'>
          <img src='./images/29.jpg' className='gallery-image' alt='image'></img>
           <p>USMID launch for batch 1 roads project - Lugazi</p>
         </article>
          <article className='gallery-content'>
          <img src='./images/30.jpg' className='gallery-image' alt='image'></img>
           <p>Cutting levels during road construction</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/31.jpg' className='gallery-image' alt='image'></img>
           <p>Preparation of blasting on a quarry project
</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/32.jpg' className='gallery-image' alt='image'></img>
           <p>USMID launch for batch 1 roads project- Lugazi and
Kamuli 
</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/33.jpg' className='gallery-image' alt='image'></img>
           <p>FDT testing during road construction
</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/17.jpeg' className='gallery-image' alt='image'></img>
           <p>Community meeting
</p>
         </article>
          <article className='gallery-content'>
          <img src='./images/18.jpeg' className='gallery-image' alt='image'></img>
           <p>Community Health outreach meeting
</p>
         </article>
          <article className='gallery-content'>
          <img src='./images/21.jpeg' className='gallery-image' alt='image'></img>
           <p>HIV/AIDS counselling and testing plus condom demonstration
</p>
         </article>
        
         
      </div>

    </section>
  )
}

export default Gallery