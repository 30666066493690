import React, {useEffect} from 'react';
import ConcreteMixerCarousel from '../components/ConcreteMixerCarousel';
import PadestrianRollerCarousel from '../components/PadestrianRoller';

const Team = () => {

useEffect(()=>{
document.title = 'Our Equipment | Clayeford Construction Services Ltd';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
          <h2 className="home-h2 team-h2">Company Owned Equipment</h2>
     <table className='team-table'>
    <tr>
      <th>No.</th>
      <th>Item of Equipment</th>
       <th>Condition </th>
      <th>Number available</th>
    
    </tr>

     <tr>
      <td colSpan={5}><strong>Earth-Moving</strong></td>  
    </tr>
    <tr>
      <td>1.</td>
      <td>Excavators</td>
       <td>Good</td>
      <td></td>    
      </tr>
      <tr>
      <td>2.</td>
      <td>Graders</td>
       <td>Good</td>
      <td>2</td>    
      </tr>

      <tr>
      <td>3.</td>
      <td>Bulldozers (D7H & D7R)</td>
       <td>Good</td>
      <td>4</td>    
      </tr>

<tr>
  <td colSpan={5}><strong>Tippers</strong></td>
</tr>
<tr>
  <td>4.</td>
  <td>Sino Trucks </td>
  <td>Good</td>
  <td>5</td>
</tr>



 </table>      

  {/* <table className='team-table'>
    <tr>
      <th>No.</th>
      <th>Item of Equipment</th>
       <th>Condition(new, good, poor)  </th>
      <th>Number available</th>
     
      <th>Year of Manufacture </th>
    </tr>

     <tr>
      <td colSpan={5}><strong>Earth-Moving</strong></td>  
    </tr>
    <tr>
      <td>1.</td>
      <td>Excavator CAT323DL - UAV 198Z</td>
       <td>Good</td>
      <td>1</td>    
      <td>2014</td>
      </tr>

    <tr>
      <td colSpan={5}><strong>Scaffolding</strong></td>  
    </tr>

     <tr>
      <td>2.</td>
      <td>Metallic scaffolding comprising of standards, ledgers, braces, base jacks, mobile caster wheels, access
ladders and dust screen</td>
       <td>New & Good</td>
      <td></td>    
      <td>N/A</td>
      </tr>

      <tr>
      <td>3.</td>
      <td>Metallic formwork comprising of fast strike beams,
plate panels</td>
       <td>New & Good</td>
      <td></td>    
      <td>N/A</td>
      </tr>

       <tr>
      <td colSpan={5}>
      <strong> Lifting Equipment</strong>
      </td>
      </tr>
      <tr>
        <td>4.</td>
        <td>Hoist/Winch1Ton</td>
        <td>Good</td>
        <td></td>
        <td>N/A</td>
       </tr>
        <tr>
        <td colSpan={5}><strong>Concrete Plant</strong></td>
       </tr>
       <tr>
        <td>5.</td>
        <td>Concrete Mixer 350 Litre Selfloading (Yellow)<br></br>
Block Making Machine
</td>
<td>
  Good<br></br>
  Good
</td>
<td>
  2<br></br>
  1
</td>
<td>
  N/A
</td>
       </tr>
<tr>
  <td>6.</td>
  <td>Diesel/Electric/Concrete Vibrators Honda GX360 </td>
  <td>Good</td>
  <td>4</td>
  <td>N/A</td>
</tr>

<tr>
  <td>7.</td>
  <td>Concrete Cube Moulds</td>
  <td></td>
  <td></td>
  <td>N/A</td>
</tr>

<tr>
  <td>8.</td>
  <td>Concrete CutterSTIHLTS400 - Spencer S/NO.51922<br></br>
Concrete CutterSTIHLTS400 - Spencer S/NO.71336</td>
  <td>Good</td>
  <td>2</td>
  <td>N/A</td>
</tr>

<tr>
  <td>9.</td>
  <td>Dewatering pump</td>
  <td>Good</td>
  <td>1</td>
  <td>N/A</td>
</tr>

<tr>
  <td>10.</td>
  <td>Diesel Generator 22 KVA</td>
  <td>Good</td>
  <td>1</td>
  <td>N/A</td>
</tr>

<tr>
  <td>11.</td>
  <td>Petrol Generator 7 KVA</td>
  <td>Good</td>
  <td>1</td>
  <td>N/A</td>
</tr>
<tr>
  <td colSpan={5}> <strong>Workshop Equipment</strong></td>
</tr>
<tr>
  <td>12.</td>
   <td>Fully Equipped Carpentry Workshop </td>
   <td>Good</td>
   <td>Complete Unit</td>
   <td>N/A</td>
</tr>
<tr>
  <td>13.</td>
   <td>Welding (Generator) </td>
   <td>Good</td>
   <td>1 Set</td>
   <td>N/A</td>
</tr>
<tr>
  <td>14.</td>
   <td>Welding Machine 300Amp</td>
   <td>Good</td>
   <td>1</td>
   <td>N/A</td>
</tr>
<tr>
  <td colSpan={5}><strong>Vehicles</strong></td>
</tr>
<tr>
  <td>15.</td>
  <td>Toyota Hilux Double Cabin – UAU 836X </td>
  <td>Good</td>
   <td>1</td>
  <td>2014 </td>
</tr>
<tr>
  <td colSpan={5}><strong>Tippers & Site Dumpers</strong></td>
</tr>
<tr>
  <td>16.</td>
  <td>Tipper Truck, 12 Tons Sino Truck – UAW 392Z </td>
  <td>Good</td>
  <td>1</td>
   <td>2014 </td>
</tr>
<tr>
  <td>17.</td>
  <td>Tipper Truck, 25Tons Sino Truck – UAW 398Z  </td>
  <td>Good</td>
  <td>1</td>
   <td>2014 </td>
</tr>

<tr>
  <td colSpan={5}><strong>General</strong></td>
</tr>
<tr>
  <td>18.</td>
  <td>Masonry Tools</td>
  <td>Good</td>
  <td></td>
  <td></td>
</tr>

<tr>
  <td>19.</td>
  <td>Dumpy Level</td>
  <td>Good</td>
  <td>1</td>
  <td></td>
</tr>

<tr>
  <td>20.</td>
  <td>Theodolite</td>
  <td>Good</td>
  <td>1</td>
  <td></td>
</tr>

<tr>
  <td>21.</td>
  <td>Terrazzo Machine </td>
  <td>Good</td>
  <td>1 Set</td>
  <td></td>
</tr>

<tr>
  <td>22.</td>
  <td>Concrete Power Floating Machine </td>
  <td>Good</td>
  <td>1 Set</td>
  <td></td>
</tr>

 </table> */}
         </div>
          <h3 className='title-h3'>Pictorial</h3>
                          
                           <div className='vm-line'></div>
                              <div className='content-container gallery gallery1'>
 
          <article className='gallery-content'>
          <img src='./images/57.jpeg' className='gallery-image' alt='image'></img>
           <p>Excavator</p>
         </article>
            <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <ConcreteMixerCarousel></ConcreteMixerCarousel>
           <p>Sino Trucks</p>
         </article>

         <article className='gallery-content'>
          <PadestrianRollerCarousel></PadestrianRollerCarousel>
          {/* <img src='./images/38.jpg' className='gallery-image' alt='image'></img> */}
           <p>Graders & Bulldozers</p>
         </article>
         
      </div>
    </section>
  )
}

export default Team