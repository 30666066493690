import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-main-slides">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/1.jpg"
          src="./images/1.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">ASTA Systems </h3>
          <p className="slide-p slide-p1">EMPOWERED BY INNOVATION</p>
        </Carousel.Caption>
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/53.jpeg"
            src="./images/3.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p3">
           We provide Energy Consultancy Services
          </p>
        </Carousel.Caption>

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/43.jpeg"
          src="./images/10.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p"> We are prominent in Real Estate Services</p>
        </Carousel.Caption>
      </Carousel.Item>
     


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="Third slide"
        />

          <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
        Providing Innovative Solutions in the Agricultural Sector
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6.jpg"
          alt="Third slide"
        />

          <Carousel.Caption>
          <h3>We Plan, Schedule, Execute & Control Projects
          </h3>
          <p className="slide-p slide-p4">
        We are also prominent in Transportation Services
          </p>
        </Carousel.Caption>
        
      </Carousel.Item> */}
      
    </Carousel>
  );
}

export default CarouselFade;